<template>
  <div class="Project">
      <template v-if="currentProject">
        <h1 class="Project__title">{{ currentProject.title }}</h1>
        <h3 class="Project__description" v-html="currentProject.longDescription && currentProject.longDescription.replace(/\\n/g, '<br/>')"></h3>
        <img class="Project__media" :src="currentProject.media" alt="project image">
        <div class="Project__details">
            <div class="Project__technos">
                <span>Technology stack</span>
                <ul class="Project__technosList">
                    <li v-for="techno in currentProject.technos" :key="techno">
                        <img :src="`/img/${techno}.svg`" alt="techno picto" height="36" >{{ techno }}  
                    </li>
                </ul>
            </div>   
            <div class="Project__links">
                <div class="Project__link">
                    <img class="Project__linkLogo" width="18" src="../assets/github.svg" alt="github logo">
                    <a :href="currentProject.sourceCode" target="_blank" rel="noopener noreferrer">{{ sourceCodeMessage }}</a> 
                </div>
                <div class="Project__link">
                    <img class="Project__linkLogo" width="18" src="../assets/earth.svg" alt="global logo">
                    <a :href="currentProject.url" target="_blank" rel="noopener noreferrer">{{ urlMessage }}</a>
                </div>
            </div>
        </div>
      </template>
      <template v-else-if="loading">
        <h1 class="Project__title">loading...</h1>
      </template>
      <template v-else>
        <h1 class="Project__title">There is no project matching this id</h1>
      </template>
  </div>
</template>

<script>
import useProjects from '@/composables/useProjects'
import { computed } from 'vue'

export default {
    name: 'Project',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup (props) {
        const { getProjects } = useProjects()
        const { projects, loading } = getProjects()
        const currentProject = computed(() => projects.value.length && projects.value.find(p => p.id == props.id))

        const sourceCodeMessage = computed(() => {
            return currentProject.value.sourceCode ? 'Check the sources on github' : `Unfortunately, the source code for ${currentProject.value.title} is not available`
        })
        const urlMessage = computed(() => {
            return currentProject.value.url ? 'See the live project' : 'Unfortunately, this gem is not deployed on the world wide web'
        })
        return {
            currentProject,
            loading,
            sourceCodeMessage,
            urlMessage
        }
    }
}
</script>

<style lang="scss" scoped>
    .Project {
        height: 100%;
        width: 100%;
        color: #aaa;

        &__description {
            
            @media (min-width: 460px) {
                padding: 0 12%;
            }
        }

        &__media {
            max-width: 100%;

            @media (min-width: 460px) {
                max-width: 25%;
            }
        }

        &__technos {
            width: 200px;
        }

        &__details {
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;

            @media (min-width: 460px) {
                flex-direction: row;
            }
        }

        &__link {
            text-align: left;
            margin-bottom: 16px;
            border: 1px solid transparent;
            border-radius: 5px;
            padding: 10px 12px 12px;
            position: relative;
            transition: border-color .8s ease-out;

            a {
                text-decoration: none;
                color: #aaa;
                vertical-align: middle;
            }

            &Logo {
                background-color: #aaa;
                border-radius: 50%;
                padding: 1px;
                position: relative;
                top: 4px;
                margin-right: 8px;
            }

            &:hover {
                border-color: #aaa;
            }
        }

        &__technosList {
            max-height: 250px;
            width: 160px;
            padding: 0 20px;
            list-style: none;
            border: 1px solid;
            border-color: #aaa;
            border-radius: 5px;
            overflow-y: scroll;

            @media (min-width: 460px) {
                width: 200px;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none;
            scrollbar-width: none;

            li:not(:last-child) {
                border-bottom: 1px solid #aaa;
                line-height: 24px;
            }

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 24px;
                padding: 10px 0;
            }
        }
    }
</style>